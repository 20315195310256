import { FC, ReactNode } from "react";

import { cn } from "@/lib/utils";

import Link from "next/link";

export interface MenuItemProps {
  href: string;
  icon: ReactNode;
  text: string;
  onClick?: () => void;
  testId?: string;
  className?: string;
}

export const MenuItem: FC<MenuItemProps> = ({ href, icon, text, onClick, testId, className }) => (
  <Link
    href={href}
    className={cn(
      "hover:bg-accent px-[8px] py-1.5 flex items-center gap-[8px] pr-4",
      className
    )}
    onClick={onClick}
    data-testid={testId}
    target={"_blank"}
  >
    {icon}
    <span className="text-sm">{text}</span>
  </Link>
);
