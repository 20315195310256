import {
  Dispatch,
  FC,
  useCallback,
  useContext,
  useReducer,
  useState,
} from "react";

import {
  AlertDialog,
  AlertDialogContent,
  Avatar,
  BaseDialog,
  Button,
  ErrorContext,
  Input,
  LoadingScreen,
  Separator,
  Spinner,
} from "@atlas-ui/components";
import { inviteUser, removeUser } from "@atlas-ui/services";
import { User } from "@atlas-ui/types";
import { UserProfile, useUser } from "@auth0/nextjs-auth0/client";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

import { isEmpty, noop } from "lodash";
import mixpanel from "mixpanel-browser";
import Image from "next/image";
import Link from "next/link";

import InviteSentIllustration from "../../../assets/atlas-invitation-sent.svg";
import { getAvatarName } from "../sections/OrganizationMembersSection";

export interface InviteDialogProps {
  isOpen: boolean;
  isOwner?: boolean;
  members?: User[];
  onMemberRemoved?: (member: User) => void;
  onMemberInvited?: () => void;
  onClose: () => void;
}

type InviteDialogMode = "default" | "remove" | "sent";

interface InviteDialogDefaultModeProps {
  state: State;
  isOwner?: boolean;
  membersToBeDisplayed?: User[];
  user: UserProfile;
  dispatch: Dispatch<Action>;
  onMemberRemoved?: (member: User) => void;
  onMemberInvited?: () => void;
}

interface InviteDialogRemoveModeProps {
  state: State;
  dispatch: Dispatch<Action>;
}

interface InviteDialogSentModeProps {
  state: State;
  dispatch: Dispatch<Action>;
}

const colors = ["bg-blue-300", "bg-popover-foreground", "bg-atlas-golden"];

interface MemberListItemProps {
  member: User;
  user: UserProfile;
  index: number;
  canDelete?: boolean;
  onRemove: () => void;
  dispatch: Dispatch<Action>;
}

const MemberListItem: FC<MemberListItemProps> = ({
  member,
  user,
  index,
  canDelete = true,
  dispatch,
  onRemove,
}) => {
  const [showRemoveConfirmation, setShowRemoveConfirmation] =
    useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  const { setError } = useContext(ErrorContext);

  const onRemoveUser = async () => {
    try {
      setIsRemoving(true);
      await removeUser(member.id);
      dispatch({
        type: "SET_MODE",
        mode: "remove",
      });
      dispatch({
        type: "SET_REMOVED_USER",
        removedUser: member,
      });
      onRemove();
    } catch (error) {
      setError({
        title: "Error",
        message: (error as Error)?.message || "Error removing user",
      });
    } finally {
      setIsRemoving(false);
      setShowRemoveConfirmation(false);
    }
  };

  return (
    <div
      className="flex items-center gap-2"
      data-testid={`member-${member.id}`}
    >
      <AlertDialog open={showRemoveConfirmation}>
        <AlertDialogContent className="p-6 flex flex-col gap-4">
          <div className="flex flex-col gap-2 h-[154px]">
            <h3 className="text-lg font-semibold">
              Remove{" "}
              <span className="text-primary">
                {member.firstName} {member.lastName}
              </span>{" "}
              from this organization?
            </h3>
            <span className="text-base text-muted-foreground">
              Any document uploaded by this user will still be accessible inside{" "}
              {member.companyName} and should be removed manually.
            </span>
            <span className="text-base front-semibold text-muted-foreground">
              You will only revoke access and credentials from this user.
            </span>
          </div>
          <div className="flex justify-end gap-2">
            <Button
              variant="ghost"
              onClick={() => {
                mixpanel.track("action-intention-remove-user");
                setShowRemoveConfirmation(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="destructive" onClick={onRemoveUser}>
              {isRemoving ? (
                <Spinner className="w-4 h-4" />
              ) : (
                <>Yes, remove user</>
              )}
            </Button>
          </div>
        </AlertDialogContent>
      </AlertDialog>
      <Avatar
        size="sm"
        fallbackClassName={`${
          colors[index % colors.length]
        } text-white text-[10px] cursor-pointer`}
        fallback={getAvatarName(
          `${member.firstName ?? member.email} ${member.lastName ?? ""}`
        )}
      />
      <div
        data-testid={`member-${member.id}`}
        className="text-sm flex flex-1 justify-between"
      >
        <div className="flex gap-2 flex-1">
          <span className="text-muted-foreground">{member.email}</span>
          {user?.sub === member.authId && (
            <span className="text-foreground">(You)</span>
          )}
        </div>
        <div>
          {canDelete && !member.onboardingComplete && (
            <button
              className="text-xs text-muted-foreground font-small mr-2"
              disabled
              data-testid="pending-member"
            >
              (Pending)
            </button>
          )}
          {canDelete && (
            <button
              className="text-xs text-primary font-medium"
              onClick={() => {
                mixpanel.track("action-intention-remove-user");
                setShowRemoveConfirmation(true);
              }}
              data-testid="remove-member"
              tabIndex={0}
            >
              Remove
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const InviteDialogDefaultMode: FC<InviteDialogDefaultModeProps> = ({
  state,
  isOwner,
  membersToBeDisplayed,
  user,
  dispatch,
  onMemberRemoved,
  onMemberInvited,
}) => {
  const { setError } = useContext(ErrorContext);
  const [isInviting, setIsInviting] = useState<boolean>(false);
  const selfMember = membersToBeDisplayed?.find(
    (member) => member.authId === user?.sub
  );
  const otherMembers = membersToBeDisplayed?.filter(
    (member) => member.authId !== user?.sub
  );

  const sendInvitation = useCallback(async () => {
    if (!state.email || isEmpty(state.email)) {
      return;
    }

    setIsInviting(true);

    try {
      await inviteUser(state.email);
      dispatch({
        type: "SET_MODE",
        mode: "sent",
      });

      mixpanel.track("action-success-invite");
      onMemberInvited?.();
    } catch (error) {
      const errorMessage = (error as Error)?.message || "Error inviting user";
      setError({
        title: (error as Error)?.name || "Error",
        message: (
          <>
            {errorMessage}
            <br />
            <span>
              Contact{" "}
              <Link className="text-primary" href="mailto:atlas-beta@nimble.la">
                support
              </Link>{" "}
              for more information.
            </span>
          </>
        ),
      });
    } finally {
      setIsInviting(false);
    }
  }, [dispatch, onMemberInvited, setError, state.email]);

  const onRemoveUserIntent = (member: User) => {
    onMemberRemoved?.(member);
  };

  return (
    <>
      <div className="flex gap-2 pt-2">
        <Input
          containerClassName="flex-1"
          type="email"
          placeholder="Add user email"
          onChange={(e) => {
            dispatch({
              type: "SET_EMAIL",
              email: e.target.value,
            });
          }}
          value={state.email}
        />
        <Button onClick={sendInvitation} className="min-w-[117px]">
          {isInviting ? <Spinner size={4} /> : "Send Invite"}
        </Button>
      </div>
      <Separator className="my-4" />
      <div className="flex flex-col gap-4">
        <span className="text-sm font-medium text-foreground">
          Contributors list
        </span>
        <div className="flex flex-col gap-2 max-h-[300px] overflow-y-auto">
          {selfMember && (
            <MemberListItem
              index={0}
              key={`modal-invite-${selfMember.id}`}
              user={user}
              member={selfMember}
              canDelete={false}
              dispatch={dispatch}
              onRemove={noop}
            />
          )}
          {otherMembers?.map((member, idx) => (
            <MemberListItem
              index={idx + 1}
              key={`modal-invite-${member.id}`}
              user={user}
              member={member}
              canDelete={isOwner}
              dispatch={dispatch}
              onRemove={() => {
                onRemoveUserIntent(member);
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const InviteDialogRemoveMode: FC<InviteDialogRemoveModeProps> = ({
  dispatch,
  state,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="py-10 flex-col flex gap-2.5 justify-center items-center">
        <CheckCircleIcon className="w-6 h-6 fill-primary" />
        <span className="text-base font-medium text-foreground text-center">
          <span className="text-primary">{state.removedUser?.email}</span> was
          removed from {state.removedUser?.organizations?.[0]?.name}.
        </span>
        <span className="text-sm font-normal text-foreground">
          This user will receive an notification through email.
        </span>
      </div>
      <div className="flex justify-end items-center">
        <Button
          onClick={() => {
            dispatch({
              type: "SET_MODE",
              mode: "default",
            });
          }}
        >
          Finish
        </Button>
      </div>
    </div>
  );
};

const InviteDialogSentMode: FC<InviteDialogSentModeProps> = ({
  state,
  dispatch,
}) => (
  <div className="flex flex-col gap-4">
    <h3 className="text-lg font-semibold text-foreground">Invite sent!</h3>
    <div className="flex flex-col justify-center gap-4 items-center">
      <Image
        src={InviteSentIllustration}
        alt="invite-sent-illustration"
        className="w-[280px]"
        width={280}
        height={280}
      />
      <div className="flex justify-center min-h-[50px] gap-1 text-foreground">
        <span> Your invite has been sent to </span>{" "}
        <a className="text-primary" href={`mailto:${state.email}`}>
          {state.email}
        </a>
      </div>
    </div>
    <div className="flex justify-end items-center">
      <Button
        onClick={() => {
          dispatch({
            type: "SET_MODE",
            mode: "default",
          });

          dispatch({
            type: "SET_EMAIL",
            email: "",
          });
        }}
      >
        Finish
      </Button>
    </div>
  </div>
);

interface State {
  mode: InviteDialogMode;
  email: string;
  removedUser: User | null;
}

type Action =
  | { type: "SET_MODE"; mode: InviteDialogMode }
  | { type: "SET_EMAIL"; email: string }
  | { type: "SET_REMOVED_USER"; removedUser: User | null };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_MODE":
      return { ...state, mode: action.mode };
    case "SET_EMAIL":
      return { ...state, email: action.email };
    case "SET_REMOVED_USER":
      return { ...state, removedUser: action.removedUser };
    default:
      return state;
  }
};

const initialState: State = {
  mode: "default",
  email: "",
  removedUser: null,
};

export const InviteDialog: FC<InviteDialogProps> = ({
  isOpen,
  isOwner,
  members,
  onMemberRemoved,
  onMemberInvited,
  onClose,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useUser();

  return (
    <BaseDialog isOpen={isOpen} onClose={onClose} className="flex flex-col">
      <div>
        {state.mode === "default" && (
          <div className="flex items-center">
            <div className="flex-1">
              <span className="text-lg font-semibold text-slate-900">
                Invite contributors
              </span>
            </div>
          </div>
        )}
        {state.mode === "default" && user && (
          <InviteDialogDefaultMode
            state={state}
            isOwner={isOwner}
            membersToBeDisplayed={members}
            user={user}
            dispatch={dispatch}
            onMemberRemoved={onMemberRemoved}
            onMemberInvited={onMemberInvited}
          />
        )}
        {state.mode === "remove" && user && state.removedUser && (
          <InviteDialogRemoveMode state={state} dispatch={dispatch} />
        )}
        {state.mode === "sent" && user && (
          <InviteDialogSentMode state={state} dispatch={dispatch} />
        )}
        {!user && <LoadingScreen />}
      </div>
    </BaseDialog>
  );
};
