"use client";

import { FC, ReactNode, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { ErrorProvider } from "@atlas-ui/components";
import { AuthenticationProvider, RealtimeProvider } from "@atlas-ui/utils";
import { UserProvider } from "@auth0/nextjs-auth0/client";

import Initializer from "./initializer";

const queryClient = new QueryClient();

const Providers: FC<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <Suspense>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <ErrorProvider>
            <RealtimeProvider>
              <AuthenticationProvider>
                <Initializer>{children}</Initializer>
              </AuthenticationProvider>
            </RealtimeProvider>
          </ErrorProvider>
        </UserProvider>
      </QueryClientProvider>
    </Suspense>
  );
};

export default Providers;
