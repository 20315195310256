import { FC } from "react";

import {
  Avatar,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@atlas-ui/components";
import { User } from "@atlas-ui/types";
import { useUser } from "@auth0/nextjs-auth0/client";

interface OrganizationMembersProps {
  members?: User[];
}

const colors = ["bg-blue-300", "bg-popover-foreground", "bg-atlas-golden"];

export const getAvatarName = (name: string) => {
  if (!name) return "";
  const [firstName, lastName] = name.split(" ");
  return `${firstName?.[0]?.toUpperCase() || ""}${
    lastName?.[0]?.toUpperCase() || ""
  }`;
};

const MemberAvatar: FC<{ member: User; index: number }> = ({
  member,
  index,
}) => {
  const avatarName = getAvatarName(
    `${member.firstName ?? member.email} ${member.lastName ?? ""}`
  );
  return (
    <TooltipProvider key={`member-${member.id}`}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Avatar
            className="-mr-2"
            size="sm"
            fallbackClassName={`${
              colors[index % colors.length]
            } text-white text-[10px] cursor-pointer`}
            fallback={avatarName}
          />
        </TooltipTrigger>
        <TooltipContent>
          {member.firstName ?? member.email} {member.lastName ?? ""}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const OrganizationMembersSection: FC<OrganizationMembersProps> = ({
  members = [],
}) => {
  const { user } = useUser();
  const filteredMembers = members
    .filter((member) => member.authId !== user?.sub)
    .slice(0, 4);

  return (
    <div
      className="flex items-center gap-1 flex-1"
      data-testid="organization-members"
    >
      <div className="flex items-center mr-2">
        {filteredMembers.map((member, index) => (
          <MemberAvatar key={member.id} member={member} index={index} />
        ))}
      </div>
      {members.length > 5 && (
        <div>
          <span className="text-muted-foreground text-sm">
            +{members.length - 4}
          </span>
        </div>
      )}
    </div>
  );
};
