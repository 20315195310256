"use client";

import {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { hotjar } from "react-hotjar";

import { AuthenticationContext } from "@atlas-ui/utils";
import { useUser } from "@auth0/nextjs-auth0/client";

import mixpanel from "mixpanel-browser";

const Initializer: FC<PropsWithChildren> = ({ children }) => {
  const [isMixpanelInitialized, setIsMixpanelInitialized] =
    useState<boolean>(false);
  const { user: auth0User } = useUser();
  const { user: docsnapUser } = useContext(AuthenticationContext);

  const initialize = async () => {
    const mixpanelToken =
      process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN ??
      "799822bfd302084a8257d9314ef1cbeb";

    if (!mixpanelToken) {
      return;
    }

    mixpanel.init(mixpanelToken, {
      ignore_dnt: true,
      persistence: "localStorage",
      track_pageview: false,
    });
    setIsMixpanelInitialized(true);

    localStorage.setItem(
      "newrelic_license_key",
      process.env["NEXT_PUBLIC_NEWRELIC_LICENSE_KEY"] ??
        "NRJS-ebae49849cd74676959"
    );
    localStorage.setItem(
      "newrelic_account_id",
      process.env["NEXT_PUBLIC_NEWRELIC_ACCOUNT_ID"] ??
        "4165684"
    );
    localStorage.setItem(
      "newrelic_application_id",
      process.env["NEXT_PUBLIC_NEWRELIC_APPLICATION_ID"] ??
        "1103279746"
    );
  };

  useLayoutEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    const hjid = Number(
      process.env.NEXT_PUBLIC_HOTJAR_HJID ?? "3812502"
    );
    const hjsv = Number(
      process.env.NEXT_PUBLIC_HOTJAR_HJSV ?? "6"
    );
    hotjar.initialize({
      id: hjid,
      sv: hjsv,
    });
    if (
      auth0User?.sub &&
      auth0User?.email &&
      docsnapUser &&
      isMixpanelInitialized
    ) {
      mixpanel.identify(auth0User.email);
      mixpanel.people.set({
        auth0Id: getAuth0Id(auth0User.sub, "auth0"),
        googleAuth0Id: getAuth0Id(auth0User.sub, "google"),
        $first_name: docsnapUser.firstName,
        $last_name: docsnapUser.lastName,
        $name: docsnapUser.firstName + " " + docsnapUser.lastName,
        $email: docsnapUser.email,
        name: docsnapUser.firstName,
        nickname: auth0User.nickname,
        createdAt: auth0User.createdAt,
        organization_name: docsnapUser.organizations?.[0]?.name,
        organization_id: docsnapUser.organizations?.[0]?.id,
      });
    }
  }, [auth0User, docsnapUser, isMixpanelInitialized]);

  return <>{children}</>;
};

const getAuth0Id = (id: string, type = "auth0") => {
  const googleString = "google-oauth2|";
  if (type === "auth0") {
    return !id.includes(googleString) ? id : undefined;
  }

  if (type === "google") {
    return id.includes(googleString) ? id : undefined;
  }

  return undefined;
};

export default Initializer;
